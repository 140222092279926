<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Performance Report</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="0" :nudge-top="0"
              transition="scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field class="hideMessage input-date" append-icon="mdi-calendar-range" v-model="picker"
                  label="Select Month" readonly dense v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="picker" type="month" @change="Selectmonth()" @input="menu = false"></v-date-picker>
            </v-menu>
          </div>
          <div class="box-S3 noPadding">
            <v-select class="hideMessage" :items="itemsListDeparture" v-model="selectDeparture" item-text="ap_name"
              item-value="ap_id" label="Departure From" return-object @change="selectDepartureFrom()"></v-select>
          </div>
        </div>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <ejs-grid ref="gridReportMTD" id="GridReportMTD" :dataSource="performanceReportMTD" :allowGrouping="false"
          :toolbar="toolbarOptions" :allowExcelExport="true" :toolbarClick="toolbarClickReportMTD" :allowPaging="false"
          :allowSorting="true" :allowMultiSorting="true">
          <e-columns>
            <e-column field='MTD' headerText='MTD' headerTextAlign='Center' width='155'></e-column>
            <e-column field='PTTEP_Flt' headerText='PTTEP Flt' textAlign='Center'></e-column>
            <e-column field='MUBADALA_Flt' headerText='Valeura Flt.' textAlign='Center'></e-column>
            <e-column field='CPOC_Flt' headerText='CPOC Flt' textAlign='Center'></e-column>
            <e-column field='CHOC_Flt' headerText='CHOC Flt' textAlign='Center'></e-column>
            <e-column field='PRP_Flt' headerText='PRP Flt' textAlign='Center'></e-column>
            <e-column field='ALL_REVENUE' headerText='ALL REVENUE' textAlign='Center'></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="box-S4" style="display: flex; justify-content: right;">
        <p><b><span style="color: white;">Data Between :</span> <span style="color: red;">{{ MTDStart }} - {{ MTDEnd
        }}</span></b></p>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <ejs-grid ref="gridReportYTD" id="GridReportYTD" :dataSource="performanceReportYTD" :allowGrouping="false"
          :toolbar="toolbarOptions" :allowExcelExport="true" :toolbarClick="toolbarClickReportYTD" :allowPaging="false"
          :allowSorting="true" :allowMultiSorting="true">
          <e-columns>
            <e-column field='MTD' headerText='YTD' headerTextAlign='Center' width='155'></e-column>
            <e-column field='PTTEP_Flt' headerText='PTTEP Flt' textAlign='Center'></e-column>
            <e-column field='MUBADALA_Flt' headerText='Valeura Flt.' textAlign='Center'></e-column>
            <e-column field='CPOC_Flt' headerText='CPOC Flt' textAlign='Center'></e-column>
            <e-column field='CHOC_Flt' headerText='CHOC Flt' textAlign='Center'></e-column>
            <e-column field='PRP_Flt' headerText='PRP Flt' textAlign='Center'></e-column>
            <e-column field='ALL_REVENUE' headerText='ALL REVENUE' textAlign='Center'></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="box-S4" style="display: flex; justify-content: right;">
        <p><b><span style="color: white;">Data Between :</span> <span style="color: red;">{{ YTDStart }} - {{ YTDEnd
        }}</span></b></p>
      </div>
    </div>

  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfMonth,
  startOfYear,
  endOfMonth,
  format,
} from "date-fns";
import {
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    menu: false,
    userdata: null,
    itemsListDeparture: [],
    picker: {},
    selectDeparture: {},
    dateSelect: new Date().toISOString().substr(0, 7),
    toolbarOptions: ["ExcelExport"],
    performanceReportMTD: [],
    performanceReportYTD: [],
    MTDStart: null,
    MTDEnd: null,
    YTDStart: null,
    YTDEnd: null,
  }),
  computed: {},
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.picker = this.dateSelect;
    await this.renderListDeparture();
    await this.GetPerformanceReport();
  },
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport],
  },
  methods: {
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
        this.selectDeparture = {
          "ap_id": airports[0].ap_id,
          "ap_nanme": airports[0].ap_name
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async GetPerformanceReport() {
      let monthNow = new Date().getMonth() + 1;
      this.data = [];
      try {
        let date = new Date(this.picker.substr(0, 4), this.picker.substr(5, 2), 0, 0, 0, 0, 0);
        let startMTD = startOfMonth(date);
        let endMTD = null;
        let startYTD = startOfYear(date);
        let endYTD = null;
        if (monthNow == this.picker.substr(5, 2)) {
          endMTD = new Date();
          endYTD = new Date();
        } else {
          endMTD = endOfMonth(date);
          endYTD = endOfMonth(date);
        }

        let qMTD = {};
        this.MTDStart = format(startMTD, "dd MMM");
        this.MTDEnd = format(endMTD, "dd MMM yyyy");
        qMTD.start = format(startMTD, "yyyy-MM-dd");
        qMTD.end = format(endMTD, "yyyy-MM-dd");
        qMTD.ap_id = this.selectDeparture.ap_id;
        this.performanceReportMTD = await feathersClientUOA.service("performance-report").find({ query: qMTD });

        let qYTD = {};
        this.YTDStart = format(startYTD, "dd MMM");
        this.YTDEnd = format(endYTD, "dd MMM yyyy");
        qYTD.start = format(startYTD, "yyyy-MM-dd");
        qYTD.end = format(endYTD, "yyyy-MM-dd");
        qYTD.ap_id = this.selectDeparture.ap_id;
        this.performanceReportYTD = await feathersClientUOA.service("performance-report").find({ query: qYTD });
      } catch (error) {
        console.log(error);
      }
    },
    async Selectmonth() {
      await this.GetPerformanceReport();
    },
    async SelectCustomer() {
      await this.GetPerformanceReport();
    },
    async selectDepartureFrom() {
      await this.GetPerformanceReport();
    },
    toolbarClickReportMTD(args) {
      if (args.item.id === "GridReportMTD_excelexport") {
        this.$refs.gridReportMTD.excelExport();
      }
    },
    toolbarClickReportYTD(args) {
      if (args.item.id === "GridReportYTD_excelexport") {
        this.$refs.gridReportYTD.excelExport();
      }
    },
  },
};
</script> 


<style></style>